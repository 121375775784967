/* Login.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
    position: fixed;
    left: 0;
    overflow: scroll;
}

.login-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    width: 100%;
}

.login-form h2 {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group textarea:focus-visible {
    outline: unset;
}

.login-button {
    width: 100%;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #285185;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}
.text {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    fill: #333;
}

.highlight {
    fill: #d67940;
    font-weight: bold;
    font-size: 35px;
}

.register-link {
    margin-top: 10px;
    text-align: center;
}

.register-link p {
    font-size: 14px;
}

.register-link a {
    color: #285185; /* İstediğin renk ile değiştirebilirsin */
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}
