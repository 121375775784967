.pagination {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: end;
}

.pagination button {
    background-color: #285185; /* Ana renk */
    border: none;
    color: white;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #0056b3; /* Hover rengi */
}

.pagination button:disabled {
    background-color: #cccccc; /* Pasif durum rengi */
    cursor: not-allowed;
}

.pagination select {
    margin-left: 10px;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}