body {
    overflow-x: hidden;
    overflow-y: scroll;
}
.app {
    display: grid;
    width: calc(100%);
    font-size: 13px;
}
#root {
    font-family: 'Poppins';
}
.w-0 {
    width: unset!important;
}
label {
    font-weight: 500!important;
}
.d-grid {
    display: grid;
}
.content {
    flex: 1 1;
    margin: 100px 0 0 -10px;
    background: #ccd9e25e;
    min-height: calc(100vh - 130px);
    padding-bottom: 20px;
}
.row {
    margin-left: 0!important;
    margin-right: 0!important;
}
.card-body {
    padding: 10px!important;
    overflow-x: scroll;
}
.card-header {
    background: #263e4a!important;
    color: #fff;
}
.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px!important;
    padding-left: 5px!important;
}

.container {
    margin: auto;
    max-width: 800px;
    margin-top: 150px;
}

.inputcontainer {
    position: relative;
}

input {
    width: 100%;
    font-size: 20px;
    box-sizing: border-box;
}

.icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
}
.loader-mini {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
}

@keyframes around {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.loader-mini::after, .loader-mini::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.loader-mini::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}

.form-group.no-border input {
    border: unset;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
}

.form-group.no-border.alertt input {
    border: unset;
    border-radius: 0px;
    border-bottom: 2px solid blue;
}

.form-group.no-border select {
    border: unset;
    border-radius: 0px;
    border-bottom: 1px solid #ddd;
}

.form-group.no-border input:focus {
    border: unset;
    border-radius: 0px;
    border-bottom: 1px solid #285185;
    outline: unset;
}

.form-group.no-border.alertt input:focus {
    border: unset;
    border-radius: 0px;
    border-bottom: 2px solid blue;
    outline: unset;
    box-shadow: unset;
}

.form-group.no-border select:focus {
    border: unset;
    border-radius: 0px;
    border-bottom: 1px solid #285185;
    outline: unset;
}

.ts-head {
    padding: 10px;
    background: #285185;
    font-weight: bold;
    color: #fff;
}

.ts-body {
    display: grid;
    gap: 5px;
    margin-top: 10px;
}
.ts-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 4px 8px;
    cursor: pointer;
    border: 1px solid #dddddd61;
}
.ts-item:hover {
    background: #f2f2f2;
}
.ts-item span:first-child {
    flex: 1;
}
.ts-item span {
    flex: 2;
}

.tsol-head {
    padding: 10px;
    background: #285185;
    font-weight: bold;
    color: #fff;
}

.tsol-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 4px 8px;
    cursor: pointer;
    border: 1px solid #dddddd61;
}
.tsol-item:hover {
    background: #f2f2f2;
}
.tsol-item span:first-child, .tsol-item span:nth-child(2) {
    flex: 1;
}
.tsol-item span {
    flex: 2;
}

.tsol-item span:nth-child(6) {
    flex: 1;
}

.tsol-item span:nth-child(7) {
    flex: 3;
}

.tsol-item span:nth-child(8) {
    flex: 1;
    text-align: center;
}

.tsol-head span[role="row"] {
    display: flex;
    justify-content: space-between;
}

.tsol-head span[role="row"] span:last-child, .tsol-item span:last-child {
    flex: 1;
}

.tsol-head span[role="row"] span:first-child, .tsol-head span[role="row"] span:nth-child(2) {
    flex: 1;
}
.tsol-head span[role="row"] span:nth-child(7) {
    flex: 3;
}
.tsol-head span[role="row"] span:nth-child(6) {
    flex: 1;
}
.tsol-head span[role="row"] span:nth-child(8) {
    flex: 1;
}

.tsol-head span[role="row"] span {
    flex: 2;
}

.ts-head span[role="row"] {
    display: flex;
    justify-content: space-between;
}

.ts-head span[role="row"] span:first-child {
    flex: 1;
}

.ts-head span[role="row"] span {
    flex: 2;
}

@media (max-width: 800px) {
    .ts-head span[role="row"] span:nth-child(3), .ts-head span[role="row"] span:nth-child(4), .ts-head span[role="row"] span:nth-child(5), .ts-head span[role="row"] span:nth-child(6) {
        display: none;
    }

    .ts-item span:nth-child(3), .ts-item span:nth-child(4), .ts-item span:nth-child(5), .ts-item span:nth-child(6) {
        display: none;
    }
}

@media (max-width: 1200px) {
    .ts-head span[role="row"] span:nth-child(5), .ts-head span[role="row"] span:nth-child(6) {
        display: none;
    }

    .ts-item span:nth-child(5), .ts-item span:nth-child(6) {
        display: none;
    }
}

.head-container {
    padding: 10px 15px;
    font-weight: bold;
    width: 100%;
    display: flex;
    justify-content: end;
}

.smart-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
}

.btn-primary {
    background: #285185!important;
    border: unset!important;
}

.react-datepicker-wrapper {
    width: 50%;
}

.product-area {
    height: 200px;
    border: 1px solid #f2f2f2;
    margin: 10px 0;
    overflow-y: scroll;
}

.product-area div {
    flex: 2;
    gap: 10px;
}

.product-area .product-item:first-child {
    font-weight: bold;
    position: sticky;
    top: 0;
}
.react-datepicker-popper {
    z-index: 999999;
}

.product-area div:last-child {
    flex: .5;
}
.product-area div:nth-child(1) {
    flex: 1;
}

.product-area div:nth-child(2) {
    flex: .7;
}

.product-area div:nth-child(3) {
    flex: 1;
}

.product-area div:nth-child(4) {
    flex: 2.5;
}

.product-area div:nth-child(5) {
    flex: .5;
}
.product-area div:nth-child(6) {
    flex: 1;
}
.product-area div:nth-child(7) {
    flex: 1;
}
.product-area div:nth-child(8) {
    flex: 1;
}
.product-area div:nth-child(9) {
    flex: .7;
}
.product-area div:nth-child(10) {
    flex: .7;
}

.product-item {
    justify-content: space-around;
    background: #f3f3f3;
    padding: 5px;
    font-size: 10px;
}

.product-item input {
    border: unset;
    border-bottom: 1px solid #ddd;
    padding: 2px;
}

.smart-card {
    font-size: 11px;
}
.table-special {
    font-size: 12px;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 11px!important;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
}

.react-datepicker__header {
    text-align: center;
    background-color: #285185!important;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    color: #fff;
    position: relative;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #fff!important;
    font-weight: bold;
    font-size: 11px!important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #fff!important;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    background: #285185;
}
.react-datepicker__month-container {
    float: left;
    background: #285185;
}

.partner-head {
    display: flex;
    align-items: center;
}

.partner-head span {
    flex: 2;
}

.partner-item span {
    flex: 2;
}

.partner-head span:last-child {
    flex: .1;
}

.partner-item span:last-child {
    flex: .1;
    cursor: pointer;
}

.partner-item {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    padding: 10px;
    gap: 10px;
}

.partner-item input {
    border: unset;
    border-bottom: 1px solid #ddd;
    padding: 2px;
}

.partner-item input:focus-visible, .partner-item input:active {
    outline: unset;
}
