.progressbar {
    background-color: rgb(220, 53, 69);
    opacity: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 5px;
    transition: opacity 0.6s;
    z-index: 9999;
}