.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.animationLogo {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: scale(1) rotate(0deg);
    }
    25% {
        transform: scale(1.3) rotate(5deg);
    }
    50% {
        transform: scale(1) rotate(0deg);
    }
    75% {
        transform: scale(1.3) rotate(-5deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

