.footer-bottom {
    height: 30px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
    background: #ccd9e2;
    color: #285185;
}
.footer-bottom p {
    margin: unset!important;
}