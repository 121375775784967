.p-dropdown {
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 9;
    border: 1px solid #ddd;
}

.pd-item {
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 7px;
}

.pd-item:hover {
    background: #ddd;
    cursor: pointer;
}