.menu-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 15px;
}

.dropdownn-menu {
    position: absolute;
    top: 30px; /* İkonun altında görünecek */
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0,0,0,0.1);
    z-index: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
}

.dropdownn-menu li {
    cursor: pointer;
}

.dropdownn-menu li button {
    background: none;
    border: 1px solid #ddd;
    color: black;
    font-size: 10px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 7px;
    text-align: center;
    margin-bottom: 10px;
}

.dropdownn-menu li button:hover {
    background-color: #f1f1f1;
}

.name-user {
    font-weight: bold;
}

.role-user {
    font-size: 11px;
    background: #285185;
    padding: 5px;
    color: #fff;
    width: 100%;
    text-align: center;
    font-weight: bold;
}