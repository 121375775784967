.app-pagination {
    display: grid;
    overflow-y: scroll;
    grid-template-columns: minmax(230px, 230px) repeat(auto-fill, 235px);
    font-size: 11px;
    justify-content: start;
    gap: 20px;
    align-content: start;
    padding: 20px 40px;
}

.app-item {
    width: 100%;
    height: 120px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background: #285185;
    position: relative;
    overflow: hidden;
}
.app-item a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: unset!important;
    color: #fff!important;
}
.app-item span {
    z-index: 1;
}

.app-item svg {
    font-size: 80px;
    color: #d67940;
    position: absolute;
    bottom: -20px;
    z-index: 0;
    left: -20px;
}


.app-pagination-category-title h4 {
    font-size: 17px;
    padding-left: 50px;
    margin-top: 20px;
}