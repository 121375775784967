.smart-card {
    width: 100%;
    padding: 10px;
    background: #fff;
    margin-left: 5px;
}

.address-info {
    font-size: 17px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: block;
}

.image-area {
    border: 1px solid #ddd;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.image-area svg {
    color: #285185;
    font-size: 17px;
}

.tabs-area {
    margin-top: 10px;
    border-bottom: 1px solid #ddd;
}

button.tabs-btn {
    background: unset;
    padding: 10px 5px;
    border: unset;
    margin-bottom: -1px;
    min-width: 150px;
    font-weight: 700;
}

button.tabs-btn.active {
    background: unset;
    border: solid;
    border-width: 1px 1px 1px 1px;
    border-color: transparent transparent #285185 transparent;
}

button.tabs-btn:focus {
    outline: unset;
}

.product-note {
    border: 1px solid #ddd;
}