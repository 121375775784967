.dashboard {
    padding: 20px;
}
dl, ol, ul {
    margin-top: unset!important;
    margin-bottom: unset!important;
}
svg.time-svg {
    width: 100%;  /* Ebeveyn div kadar genişlik */
    height: 100%; /* Ebeveyn div kadar yükseklik */
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyMTU3NjkiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=');
    background-size: 100%;
    background-image: linear-gradient(#4f3a3a 60%, #00607d 100%);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.bgDot {
    stroke: none;
    fill: #215769;
}

.clockCircle {
    fill: none;
    stroke: #2a2a2a;
}

.clockArc {
    fill: none;
    stroke: #1bbccb;
}

.clockDot {
    fill: #e9fafc;
}

.caption {
    font-family: sans-serif;
    font-weight: 300;
    fill: White;
}

.dayText {
    font-size: 2.7rem;
}

.dateText {
    font-size: 2rem;
    font-weight: 400;
}

.timeText {
    font-family: sans-serif;
    font-size: 5rem;
    font-weight: 600;
    fill: White;
}
.home-counter {
    height: 100%;
    background: #285185;
    display: grid;
    padding: 10px 20px;
    color: #fff;
    border-radius: 5px;
    align-items: center;
}
.home-counter div {
    font-size: 20px;
}
.home-counter p {
    font-size: 13px;
}
.home-spec-span {
    background: #c6d3dc;
    border-radius: 5px;
    color: #285185;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px 15px;
}